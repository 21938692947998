html{
    scroll-behavior: smooth;
}

.foi-header {
    padding-top: 24px;

    .header-content {
        padding-top: 105px;
        padding-bottom: 40px;

        h1 {
            font-size: 45px;
            line-height: 1.22;
        }
    }
    &.landing-header {
        background-image: url('../images/bg_1@2x.png'),url("../images/Bg_2@2x.png");
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-size: 167px, 65%;
        background-position: left bottom, right top;
    
    }
}

.landing-feature {

    &-icon {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: theme-color();
        margin-right: 1rem;

        &::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 7px;
            border: 2px solid $white;
            border-color: transparent transparent $border-color $border-color;
            transform: rotate(-45deg);
            position: relative;
            top: -6px;
            left: 4px;
        }
    }
    p {
        font-size: 14px;
        color: $text-muted;
        line-height: 1.79;
    }
}

.foi-rating {
    .fa-star {
        color: $text-muted;
        font-size: 15px;
    }
    .checked {
        color: theme-color(warning);
    }
    margin-bottom: 12px;
}

.foi-review {
    .foi-review-heading {
        font-size: 18px;
        margin-bottom: 18px;
    }
    .foi-review-content {
        font-size: 14px;
        line-height: 1.71;
        color: $text-muted;
        margin-bottom: 28px;
    }
    .foi-review-user {
        .avatar {
            height: 35px;
            width: 35px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px;
        }
        p{
            font-size: 12px;
            color: $text-muted;
            margin-bottom: 0;
        }
    }

}

.page-header {
    padding-top: 80px;
    padding-bottom: 75px;

    @media (max-width: 767px){
        padding-top: 30px;
        padding-bottom: 35px;
    }

    h2 {
        font-size: 30px;
        line-height: 1.33;
    }
}

.about-section-one-title {
    font-size: 38px;
    margin-bottom: 28px;
}

.about-section-one-content {
    max-width: 380px;
}

.foi-page-section {
    padding-top: 75px;
    padding-bottom: 75px;
}

.about-section-two-title {
    font-size: 30px;
    max-width: 334px;
    margin-bottom: 24px;
}

.about-section-two-content {
    max-width: 395px;
}

.team-section-title {
    @extend .font-os;
    font-weight: $font-weight-semibold;
    text-align: center;
    line-height: 1.33;
    margin-bottom: 69px;
}

.team-member-card {

    text-align: center;

    .team-member-avatar {
        width: 108px;
        height: 108px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 24px;
    }

    .team-member-name {
        margin-bottom: 0;
        line-height: 1;
    }

    .team-member-designation {
        @extend .font-os;
        font-size: 14px;
        line-height: 2.86;
        color: $text-muted;
        margin-bottom: 0;
    }
}

.foi-feature {
    margin-bottom: 46px;

    .feature-icon {
        width: 30px;
        margin-bottom: 17px;
    }

    .feature-title {
        line-height: 1.75;
        font-size: 20px;
    }

    .feature-content {
        font-size: 18px;
        color: theme-color(dark);
        margin-bottom: 12px;
        line-height: 1.56;
    }

    .feature-link{
        font-size: 14px;
        font-weight: $font-weight-bold;
        color: theme-color(primary);

        @include hover-focus {
            color: darken(theme-color(primary), 5%);
        }
    }
}

.feature-faq-title {
    font-size: 30px;
    line-height: 1.33;
    margin-bottom: 24px;
}

.contact-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.contact-widget {
    padding: 22px 45px 14px;
    align-items: flex-end;

    @media (max-width: 767px) {
        padding: 22px 0;
    }

    &:first-child {
        border-right: 1px solid #e0e0e0;
        padding-left: 0;

        @media (max-width: 767px) {
            border-right: 0;
            border-bottom: 1px solid #e0e0e0;
        }
    }

    img {
        margin-right: 20px;
    }
    .widget-title {
        margin-bottom: 0;
    }

    .widget-content {
        font-size: 13px;
        line-height: 1;
        margin-bottom: 0;

    }
}

.career-section {
    margin-bottom: 150px;
}

.auth-section-title {
    font-size: 30px;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    line-height: 1.33
}
.auth-section-subtitle {
    font-size: 14px;
    line-height: 1.79;
    color: $text-muted;
    margin-bottom: 30px;
}
.auth-wrapper {
    padding-top: 100px;
    padding-bottom: 150px;

    @media (max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 75px;
    }
}

.page-error {
    padding-top: 150px;
    padding-bottom: 200px;

    @media (max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 75px;
    }
}

.error-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .error-code {
        font-size: 90px;
        line-height: 1;
        margin-bottom: 8px;
    }

    .error-message {
        font-size: 14px;
        line-height: 1.79;
        color: $text-muted;
        margin-bottom: 59px;
    }

    .error-img {
        width: 350px;
        max-width: 100%;
        margin-bottom: 47px;
    }

    .btn-error-action{
        padding: 16px 28px;
    }
}

.privacy-policy-section {
    padding-top: 0;
    padding-bottom: 150px;

    @media (max-width: 767px){
        padding-top: 30px;
        padding-bottom: 55px;
    }
}

.privay-policy-card {
    border: 0;
    border-radius: 6px;
    box-shadow: 0 2px 30px 0 rgba(207, 207, 207, 0.5);

    .card-body{
        padding: 35px 60px;

        @media (max-width: 767px){
            padding: 24px;
        }
    }

    .policy-title {
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        line-height: 1.71;
        color: theme-color(dark);
        margin-bottom: 35px;
    }
}